import { Base, withMountWhenInView } from '@studiometa/js-toolkit';
import type { BaseProps } from '@studiometa/js-toolkit';
import { gsap } from 'gsap';
export interface ClubSponsorsProps extends BaseProps {
  $refs: {
    desktop: HTMLElement | HTMLElement[];
    mobile: HTMLElement | HTMLElement[];
    gov: HTMLElement | HTMLElement[];
    org: HTMLElement | HTMLElement[];
    link: HTMLElement | HTMLElement[];
    logo: HTMLElement | HTMLElement[];
  };
  $options: {
    CHANGE_TIME: number;
    HIDE_DELAY: number;
    PARTNERS_DESKTOP: number;
  };
}
export class ClubSponsors extends withMountWhenInView(Base)<ClubSponsorsProps> {
  static config = {
    name: 'ClubSponsors',
    refs: ['desktop', 'mobile', 'gov', 'org', 'link', 'logo'],
    options: {
      CHANGE_TIME: {
        type: Number,
        default: 5000,
      },
      HIDE_DELAY: {
        type: Number,
        default: 300,
      },
      PARTNERS_DESKTOP: {
        type: Number,
        default: 3,
      },
    },
    log: false,
  };
  private CURRENT: number;
  private status: { [key: string]: boolean };
  private initLogos: { [key: string]: any[] };
  private currentLogos: { [key: string]: any[] };
  private rotationInterval: number | null;
  mounted() {
    this.$log('ClubSponsors component is visible!');
    this.$log('$refs:', this.$refs);
    this.$log('$el:', this.$el);
    this.$log('$options:', this.$options);

// Check if the component is properly mounted
    if (!this.$el) {
      this.$log('Component not properly mounted. $el is undefined.');
      return;
    }

    this.CURRENT = this.$options.PARTNERS_DESKTOP - 1;
    this.status = {
      partners: false,
      governments: false,
      organizations: true,
    };

    this.$log('Calling initSponsorsData');
    this.initSponsorsData();

    this.$log('Calling animateInitialLogos');
    this.animateInitialLogos();

    if (this.shouldRotate()) {
      this.$log('Starting rotation');
      this.startRotation();
    } else {
      this.$log('Not starting rotation');
    }
  }
  destroyed() {
    this.$log('ClubSponsors component is no longer visible.');
    if (this.rotationInterval) {
      clearInterval(this.rotationInterval);
    }
  }
  private initSponsorsData() {
    this.initLogos = {
      partners: this.getSponsorsData(this.$refs.desktop),
      governments: this.getSponsorsData(this.$refs.gov),
      organizations: this.getSponsorsData(this.$refs.org),
    };
    this.currentLogos = {
      partners: [...this.initLogos.partners],
      governments: [...this.initLogos.governments],
      organizations: [...this.initLogos.organizations],
    };
    this.$log('Initialized sponsor data:', this.initLogos);
  }
  private getSponsorsData(elements: HTMLElement | HTMLElement[] | undefined): any[] {
    if (!elements) {
      return [];
    }
    const elementsArray = Array.isArray(elements) ? elements : [elements];
    return elementsArray.map(element => ({
      url: element.querySelector('[data-ref="link"]')?.getAttribute('href') || '#',
      logo: element.querySelector('[data-ref="logo"]')?.getAttribute('src') || '',
      title: element.querySelector('[data-ref="logo"]')?.getAttribute('alt') || '',
    }));
  }
  private animateInitialLogos() {
    const allLogos = [
      ...(Array.isArray(this.$refs.logo) ? this.$refs.logo : [this.$refs.logo]),
    ].filter(Boolean);

    this.$log('Animating logos, total count:', allLogos.length);

    allLogos.forEach((logo, index) => {
      if (logo instanceof HTMLElement) {
        this.$log('Processing logo:', logo);
        gsap.to(logo, {
          opacity: 1,
          scale: 1,
          duration: 0.5,
          ease: "power2.out",
          delay: index * 0.1,
          onStart: () => {
            this.$log('Animation started for logo:', logo);
          },
          onComplete: () => {
            this.$log('Animation completed for logo:', logo);
          }
        });
      } else {
        this.$log('Invalid logo element:', logo);
      }
    });
  }

  private shouldRotate(): boolean {
    return (this.initLogos.partners.length > this.$options.PARTNERS_DESKTOP) ||
      (this.initLogos.governments.length > 1) ||
      (this.initLogos.organizations.length > 1);
  }
  private startRotation() {
// Delay the start of rotation to allow initial animation to complete
    setTimeout(() => {
      this.rotationInterval = window.setInterval(() => this.rotateLogo(), this.$options.CHANGE_TIME);
    }, (Array.isArray(this.$refs.desktop) ? this.$refs.desktop.length : 1) * 100 + 500);
  }
  private rotateLogo() {
    this.$log('Rotating logos');
    this.rotateDesktopPartners();
    this.rotateMobileSponsors('partners');
    this.rotateMobileSponsors('governments');
    this.rotateMobileSponsors('organizations');
    this.updateStatus();
  }
  private rotateDesktopPartners() {
    if (!this.$refs.desktop || this.initLogos.partners.length <= this.$options.PARTNERS_DESKTOP) return;

    const nextIndex = (this.CURRENT + 1) % this.initLogos.partners.length;
    const nextSponsor = this.initLogos.partners[nextIndex];
    const slotToUpdate = nextIndex % this.$options.PARTNERS_DESKTOP;

    const desktopElements = Array.isArray(this.$refs.desktop) ? this.$refs.desktop : [this.$refs.desktop];
    if (desktopElements[slotToUpdate]) {
      this.updateLogo(desktopElements[slotToUpdate], nextSponsor);
    }
    this.CURRENT = nextIndex;
  }
  private rotateMobileSponsors(type: 'partners' | 'governments' | 'organizations') {
    if (this.initLogos[type].length <= 1) return;

    const nextIndex = (this.currentLogos[type].length > 0) ? 0 : this.initLogos[type].length - 1;
    const nextSponsor = this.initLogos[type][nextIndex];
    const elements = this.$refs[type === 'partners' ? 'mobile' : type];

    const elementsArray = Array.isArray(elements) ? elements : [elements];
    if (elementsArray.length > 0) {
      this.updateLogo(elementsArray[0], nextSponsor);
    }
    this.currentLogos[type] = this.initLogos[type].filter((_, index) => index !== nextIndex);
  }
  private updateLogo(element: HTMLElement, sponsor: any) {
    const link = element.querySelector('[data-ref="link"]') as HTMLAnchorElement;
    const logo = element.querySelector('[data-ref="logo"]') as HTMLImageElement;

    if (!link || !logo) {
      this.$log('Link or logo element not found for update:', element);
      return;
    }

    gsap.to(logo, {
      opacity: 0,
      scale: 0,
      duration: 0.3,
      onComplete: () => {
        link.href = sponsor.url;
        logo.src = sponsor.logo;
        logo.alt = sponsor.title;

        gsap.to(logo, {
          opacity: 1,
          scale: 1,
          duration: 0.3,
          ease: "power2.out"
        });
      }
    });
  }
  private updateStatus() {
    if (this.status.partners) {
      this.status.partners = false;
      this.status.governments = true;
    } else if (this.status.governments) {
      this.status.governments = false;
      this.status.organizations = true;
    } else if (this.status.organizations) {
      this.status.organizations = false;
      this.status.partners = true;
    }
  }
}
