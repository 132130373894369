import { Base } from '@studiometa/js-toolkit';
import type { BaseProps, BaseConfig } from '@studiometa/js-toolkit';
import { gsap } from 'gsap';
import { Curtain } from '../../atoms';

interface NavigationContextualMobileProps extends BaseProps {
  $el: HTMLElement;
  $options: {
    isOpen: boolean;
  };
  $refs: {
    toggle: HTMLElement;
    left: HTMLElement;
    right: HTMLElement;
    content: HTMLElement;
    links: HTMLElement[];
  };
}

/**
 * @class NavigationContextualMobile
 * @classdesc Represents a navigation dropdown menu.
 * @extends {Base<NavigationContextualMobile>}
 */
export class NavigationContextualMobile extends Base<NavigationContextualMobileProps> {
  /**
   * Component config.
   */
  static config: BaseConfig = {
    name: 'NavigationContextualMobile',
    refs: [
      'toggle',
      'left',
      'right',
      'content',
      'links[]',
    ],
    emits: ['curtainAction'],
  };

  private curtain: Curtain | null = null;

  private isOpen: boolean;

  private timeline: gsap.core.Timeline | null;

  public mounted(): void {

  }

  onToggleClick() {
    this[this.isOpen ? 'close' : 'open']();
  }

  open() {
    if (!this.isOpen) {
      // prettier-ignore

      this.timeline = gsap.timeline({
        onComplete: () => {
          this.isOpen = true;
        }
      })
      this.timeline.call(() => {
        document.dispatchEvent(new CustomEvent('curtainAction', { detail: { action: 'show'} }));
      }, []);
      this.timeline
        .to(this.$refs.left, {
          rotate: -45,
          duration: 0.7,
          ease: 'power4.inOut'
        }, '0')
        .to(this.$refs.right, {
          rotate: 45,
          duration: 0.7,
          ease: 'power4.inOut'
        }, '0')
        .to(this.$refs.content, {
          height: 'auto',
          duration: 0.3,
          ease: 'power2.inOut'
        }, '+0.15')
        .from(this.$refs.links, {
          y(index) {
            return -60 + (index * -5)
          },
          stagger: 0.015,
          duration: 0.55,
          ease: 'power2.out'
        }, '-=0.45')
        .from(this.$refs.links, {
          autoAlpha: 0,
          stagger: 0.015,
          duration: 0.5,
          ease: 'power2.out'
        }, '-=0.55')

      this.timeline.play();
    } else if (this.isOpen) {
      throw new TypeError('Menu already open.');
    }
  }

  close() {
    this.timeline = gsap.timeline({
      onComplete: () => {
        this.isOpen = false;
        gsap.set([this.$refs.links, this.$refs.content], {clearProps: 'all'})
        document.dispatchEvent(new CustomEvent('curtainAction', {detail: {action: 'clear'}}));
      }
    })
      .to(this.$refs.left, {
        rotate: 45,
        duration: 0.7,
        ease: 'power4.inOut'
      }, '0')
      .to(this.$refs.right, {
        rotate: -45,
        duration: 0.7,
        ease: 'power4.inOut'
      }, '0')
      .to(this.$refs.content, {
        height: '0',
        duration: 0.3,
        ease: 'power2.inOut'
      }, '+0.15')
      .to(this.$refs.links, {
        y(index) {
          return -60 + (index * -5)
        },
        stagger: 0.015,
        duration: 0.45,
        ease: 'power1.in'
      }, '0')
      .to(this.$refs.links, {
        autoAlpha: 0,
        stagger: 0.015,
        duration: 0.4,
        ease: 'power1.in'
      }, '+0.05')

    if (this.isOpen) {
      this.timeline.play();
      document.dispatchEvent(new CustomEvent('curtainAction', { detail: { action: 'hide'} }));
    }
  }

  public destroyed(): void {
    console.log('mobile destroyed')
  }
}
