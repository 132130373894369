import {Base, BaseInterface} from '@studiometa/js-toolkit';
import {keyCodes} from '@studiometa/js-toolkit/utils';
import type {BaseProps, BaseConfig} from '@studiometa/js-toolkit';

import {gsap} from 'gsap';
import {NavigationMobileToggle} from './NavigationMobileToggle';

import {siblings, getParents, html, queryAll} from '../../utils';

interface NavigationMobileProps extends BaseProps {
  $el: HTMLElement;
  $children: {
    NavigationMobileToggle: NavigationMobileToggle[];
  };
  $refs: {
    trigger: HTMLElement;
    backButtons: HTMLElement[];
    wrapper: HTMLElement;
    scroll: HTMLElement;
    panel: HTMLElement;
    items: HTMLElement[];
    links: HTMLElement[];
  };
}

/**
 * @class NavigationMobile
 * @classdesc  Manages the behavior and state of a mobile navigation menu.
 * @extends Base<T & NavigationMobileProps>
 */
export class NavigationMobile<T extends BaseProps = BaseProps>
  extends Base<T & NavigationMobileProps>
  implements BaseInterface
{
  /**
   * Component config.
   */
  static config: BaseConfig = {
    name: 'NavigationMobile',
    components: {NavigationMobileToggle},
    refs: ['trigger', 'backButtons[]', 'wrapper', 'scroll', 'panel', 'items[]', 'links[]']
  };

  /**
   * Indicates whether the navigation is currently open.
   * @private
   */
  private isOpen: boolean = false;

  /**
   * References the current active navigation item.
   * @private
   */
  private current: HTMLElement = null;

  /**
   * Represents the current links in the document.
   * @type {NodeListOf<Element>}
   */
  private currentLinks: NodeListOf<Element> = null;

  /**
   * Represents the current back button element.
   * @type {HTMLElement}
   */
  private currentBackButton: HTMLElement = null;

  /**
   * References the previously active navigation item.
   * @private
   */
  private previous: HTMLElement = null;

  /**
   * Represents a NodeListOf<Element> that contains previous links.
   * @type {NodeListOf<Element>}
   */
  private previousLinks: NodeListOf<Element> = null;


  /**
   * Represents the previous back button element.
   * @type {HTMLElement}
   */
  private previousBackButton: HTMLElement = null;

  /**
   * Tracks the current level depth of the navigation.
   * @private
   */
  private level: number = 0;

  get trigger() {
    return this.$children.NavigationMobileToggle[0];
  }

  /**
   * Binds necessary event listeners for the component.
   * Invoked in the {@link #mounted} hook.
   * @this {NavigationMobile & NavigationMobileProps}
   * @public
   */
  public mounted(): void {
    this.initOptionsAndBooleans();
    this.$refs.wrapper.style.display = 'block';
    this.bindListeners();
  }

  /**
   * Initializes local state variables of the component.
   * Sets the initial state for `isOpen`, `current`, `previous`, and `level`.
   * This method ensures the component starts with a consistent, known state.
   * It's typically called in the {@link #mounted} method.
   * @this {NavigationMobile & NavigationMobileProps}
   * @private
   */
  private initOptionsAndBooleans(): void {
    this.isOpen = false;
    this.current = null;
    this.previous = null;
    this.level = 0;
  }

  /**
   * Binds necessary event listeners for the component.
   * Invoked in the {@link mounted} hook.
   * @this {NavigationMobile & NavigationMobileProps}
   * @private
   */
  private bindListeners(): void {
    this.$refs.links.forEach(link => link.addEventListener('click', this.onItemClick));
    this.$refs.backButtons.forEach(button => button.addEventListener('click', this.prevLevel));
    window.addEventListener('keydown', this.onEscape);
    this.$children.NavigationMobileToggle[0].$on('Toggle.mobileMenu.close', () => {
      if (this.level > 0) {
        this.close();
      }
    });
  }

  /**
   * Unbinds event listeners, counterpart to {@link bindListeners}.
   * @this {NavigationMobile & NavigationMobileProps}
   * @private
   */
  private unbindListeners(): void {
    this.$refs.links.forEach(link => link.removeEventListener('click', this.onItemClick));
    this.$refs.backButtons.forEach(button => button.removeEventListener('click', this.prevLevel));
    window.removeEventListener('keydown', this.onEscape);
  }

  /**
   * Handles item click events within the navigation.
   * Facilitates navigation between levels using {@link nextLevel}.
   * @this {NavigationMobile & NavigationMobileProps}
   * @param {Event} event - The click event.
   * @private
   */
  private onItemClick = (event: Event): void => {
    const target = event.target as HTMLElement;
    const li = target.parentNode as HTMLElement;
    const level = li.querySelector('[data-nav-level]') as HTMLElement;

    const currentUl: HTMLElement | null = target.closest('li')?.querySelector('ul');
    if (currentUl) {
      this.currentLinks = currentUl.querySelectorAll(':scope > li > a');
      this.currentBackButton = li.querySelector('div[data-ref="containers[]"] a[data-ref="backButtons[]"]') as HTMLElement;
    }

    const previousUl = target.closest('li')?.parentElement as HTMLElement;
    if (previousUl) {
      this.previousBackButton = target
        .closest('[data-ref="containers[]"]')
        ?.querySelector('a[data-ref="backButtons[]"]');
      this.previousLinks = previousUl.querySelectorAll(':scope > li > a');
    }

    if (level) {
      event.preventDefault();

      this.previous = this.current;
      this.current = li;

      level.style.display = 'block';
      this.nextLevel();
    }
  }

  /**
   * Closes the mobile navigation.
   * Can be triggered by {@link toggle}, {@link onEscape}, or other external events.
   * @this {NavigationMobile & NavigationMobileProps}
   * @private
   */
  private close(): void {
    html.classList.remove('scroll-locked');
    this.$el.classList.remove('is-open');
    this.isOpen = false;
    this.$refs.links.forEach(item => item.style.display = 'block');
    this.setLevel({level: 0, duration: 0.1});
  }

  /**
   * Handles the escape key event for closing the navigation.
   * Linked to {@link close}.
   * @this {NavigationMobile & NavigationMobileProps}
   * @param {KeyboardEvent} escapeEvent - The escape key event.
   * @private
   */
  private onEscape = (escapeEvent: KeyboardEvent) => {
    if (!this.isOpen) return;
    if (escapeEvent.keyCode === keyCodes.ESC) this.close();
  }

  /**
   * Navigates to the next level in the navigation.
   * Triggered by {@link onItemClick}.
   * @this {NavigationMobile & NavigationMobileProps}
   * @private
   */
  private nextLevel(): void {
    this.setLevel({ dir: 'next', level: this.level + 1 });
  }

  /**
   * Manages navigation to the previous level.
   * Invoked in {@link onItemClick}.
   * @this {NavigationMobile & NavigationMobileProps}
   * @param {Event} event - The click event.
   * @private
   */
  private prevLevel = (event: Event): void => {
    event.preventDefault();
    this.previous = this.current;
    this.current = getParents(this.current?.parentNode, '[data-ref="items[]"]')[0] as HTMLElement;
    const target = event.target as HTMLElement;

    const previousUl: HTMLElement | null = target.closest('li')?.querySelector('ul');
    if (previousUl) {
      this.previousLinks = previousUl.querySelectorAll(':scope > li > a');
      this.previousBackButton = target;
    }

    const currentUl = target.closest('li')?.parentElement as HTMLElement;
    if (currentUl) {
      this.currentLinks = currentUl.querySelectorAll(':scope > li > a');
      this.currentBackButton = target
        .closest('nav[data-nav-level]')
        ?.closest('[data-ref="containers[]"]')
        ?.querySelector('a[data-ref="backButtons[]"]') as HTMLElement;
    }

    this.setLevel({ dir: 'prev', level: this.level - 1 });
  }

  /**
   * Sets the navigation level.
   * Used by {@link nextLevel} and {@link prevLevel}.
   * @this {NavigationMobile & NavigationMobileProps}
   * @param {Object} opts - Options for setting the level.
   * @private
   */
  private setLevel(opts: any = {}): void {
    const options = { dir: 'none', level: this.level, ...opts };
    this.$refs.scroll.scrollTop = 0;
    const vars = {
      x: `${-options.level * 100}%`,
    };

    if (options.dir === 'prev') {
      gsap.to([
        ...(this.previousBackButton ? [this.previousBackButton] : []),
        this.previousLinks
      ], {
        autoAlpha: 0,
        x: '100%',
        duration: 0.4,
        stagger: 0.05,
        ease: 'expo.in',
        onComplete: () => {
          gsap.set(this.$refs.panel, vars);
          this.hideLevelElements({ level: options.level + 1, not: options.level });
          gsap.fromTo([
            ...(this.currentBackButton ? [this.currentBackButton] : []),
            this.currentLinks
          ], {
            autoAlpha: 0,
            x: '-100%'
          }, {
            autoAlpha: 1,
            x: 0,
            duration: 0.4,
            stagger: 0.05,
            ease: 'expo.out',
          })
          siblings(this.previous).forEach((item: HTMLElement) => {
            item.style.display = 'block';
          });
        }
      })
    }

    if (options.dir === 'next') {
      gsap.to([
        ...(this.previousBackButton ? [this.previousBackButton] : []),
        this.previousLinks
      ], {
        autoAlpha: 0,
        x: '-100%',
        duration: 0.4,
        stagger: 0.05,
        ease: 'expo.in',
        onComplete: () => {
          gsap.set(this.$refs.panel, vars)
          const anim = gsap.to(this.$refs.panel, vars);
          this.hideLevelElements({ level: options.level + 1, not: options.level });
          gsap.to([
            ...(this.currentBackButton? [this.currentBackButton] : []),
            this.currentLinks
          ], {
            autoAlpha: 1,
            x: 0,
            duration: 0.4,
            stagger: 0.05,
            ease: 'expo.out',
          })
          gsap.set(this.previousLinks, {clearProps: 'all'});
          siblings(this.current).forEach((item: HTMLElement) => {
            item.style.display = 'none';
          });
        }
      })
    }

    this.level = options.level;
  }

  /**
   * Hides elements at a specific level in the navigation.
   * Called by {@link setLevel}.
   * @this {NavigationMobile & NavigationMobileProps}
   * @param {Object} opts - Options for hiding elements.
   * @private
   */
  private hideLevelElements(opts: Object = {}): void {
    const els = this.getLevelElements(opts) as Array<HTMLElement>;

    els.forEach((l) => {
      l.style.display = 'none';
    });
  }

  /**
   * Retrieves elements at a specified level in the navigation.
   * Utilized by {@link setLevel} and {@link hideLevelElements}.
   * @this {NavigationMobile & NavigationMobileProps}
   * @param {Object} opts - Options for retrieving elements.
   * @returns {HTMLElement[]} - Elements at the specified level.
   * @private
   */
  private getLevelElements(opts: any = {}) {
    let levels = Array.from(this.$refs.wrapper.querySelectorAll('[data-ref="containers[]"]')) as Array<HTMLElement>;

    if (opts.level) {
      levels = levels.filter((level) => level.dataset.navLevel == opts.level);
    }

    if (opts.not) {
      levels = levels.filter((level) => level !== opts.not);
    }

    return levels;
  }

  /**
   * Resets styles to their initial state upon closing the navigation.
   * @this {NavigationMobile & NavigationMobileProps}
   * Complements {@link close}.
   * @private
   */
  private resetStyles(): void {
    this.$refs.wrapper.style.display = '';
    this.$refs.panel.style.transform = '';
    this.getLevelElements().forEach((el) => {
      el.removeAttribute('style');
    });
    gsap.set([this.$refs.backButtons, this.$refs.items, this.$refs.links], {clearProps: 'all'});
  }

  /**
   * Cleans up the component upon destruction, resetting styles and state.
   * @this {NavigationMobile & NavigationMobileProps}
   * Complements {@link #mount} and {@link bindListeners}.
   * @public
   */
  public destroyed(): void {
    this.unbindListeners();
    this.resetStyles();
    this.close();
  }
}
