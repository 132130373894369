// eslint-disable-next-line import/no-unresolved
import '@css/app.pcss';
import * as CookieTranslationDE from './translations/CookieTranslationDE.json';
import * as CookieConsent from "vanilla-cookieconsent";
import {Translation} from "vanilla-cookieconsent";
import {Base, createApp} from '@studiometa/js-toolkit';
import type {BaseConfig, BaseProps} from '@studiometa/js-toolkit';
import {Curtain} from '../../templates/_ui/atoms';
import {ScrollToTop} from '../../templates/_ui/atoms';
import {AnimatedButton} from '../../templates/_ui/atoms';
import {Navigation} from '../../templates/_ui/organisms';
import {FigureImage} from '../../templates/_ui/molecules';
import {Hero} from '../../templates/_ui/molecules';
import {ClubSponsors} from "../../templates/_ui/organisms";
import type NavigationContextual from "../../templates/_ui/molecules/navigation/NavigationContextual.js";
import type Breadcrumbs from "../../templates/_ui/molecules/breadcrumbs/Breadcrumbs";
import type MobileGridColumnSlider from '../../templates/_ui/organisms/grid/MobileGridColumnSlider';
import type ImageSlider from "../../templates/_ui/molecules/imageSlider/ImageSlider";
import type LargeText from '../../templates/_ui/atoms/largeText/LargeText.js';
import type AnimatedLayer from "../../templates/_ui/atoms/animatedLayer/AnimatedLayer.js";
import type Cap from "../../templates/_ui/molecules/cap/Cap.js";
import type Podcast from "../../templates/_ui/atoms/podcast/Podcast";
import type Gallery from "../../templates/_ui/molecules/gallery/Gallery.js";
import type Video from "../../templates/_ui/atoms/video/Video.js";

export interface AppProps extends BaseProps {
  $children: {
    Curtain: Curtain[];
    ScrollToTop: ScrollToTop[];
    AnimatedButton: AnimatedButton[];
    Navigation: Navigation[];
    FigureImage: FigureImage[];
    Hero: Hero[];
    ClubSponsors: ClubSponsors[];
    Breadcrumbs: Promise<Breadcrumbs>[];
    MobileGridColumnSlider: Promise<MobileGridColumnSlider>[];
    ImageSlider: Promise<ImageSlider>[];
    NavigationContextual: Promise<NavigationContextual>[];
    Accordion: Promise<NavigationContextual>[];
    LargeText: Promise<LargeText>[];
    Layer: Promise<AnimatedLayer>[];
    Cap: Promise<Cap>[];
    Podcast: Promise<Podcast>[];
    Gallery: Promise<Gallery>[];
    Video: Promise<Video>[];
  };
}

CookieConsent.run({
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom left",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "box",
      position: "right",
      equalWeightButtons: true,
      flipButtons: false
    }
  },
  categories: {
    necessary: {
      enabled: true,
      readOnly: true
    },
    analytics: {}
  },
  language: {
    default: 'de',
    translations: {
      'de': CookieTranslationDE as Translation
    }
  }
});

/**
 * App class.
 */
class App extends  Base<AppProps> {
  static config: BaseConfig = {
    name: 'App',
    emits: ['curtainAction'],
    components: {
      Curtain,
      ScrollToTop,
      AnimatedButton,
      Navigation,
      FigureImage,
      Hero,
      ClubSponsors,
      NavigationContextual: () => import('../../templates/_ui/molecules/navigation/NavigationContextual.js'),
      Accordion: () => import('../../templates/_ui/molecules/accordion/Accordion.js'),
      Breadcrumbs: () => import('../../templates/_ui/molecules/breadcrumbs/Breadcrumbs.js'),
      MobileGridColumnSlider: () => import('../../templates/_ui/organisms/grid/MobileGridColumnSlider.js'),
      ImageSlider: () => import('../../templates/_ui/molecules/imageSlider/ImageSlider.js'),
      LargeText: () => import('../../templates/_ui/atoms/largeText/LargeText.js'),
      AnimatedLayer: () => import('../../templates/_ui/atoms/animatedLayer/AnimatedLayer'),
      Cap: () => import('../../templates/_ui/molecules/cap/Cap.js'),
      Podcast: () => import('../../templates/_ui/atoms/podcast/Podcast.js'),
      Gallery: () => import('../../templates/_ui/molecules/gallery/Gallery.js'),
      Video: () => import('../../templates/_ui/atoms/video/Video.js'),
    },
  };

  public mounted(): void {
    let t = navigator.userAgent.toLowerCase();
    if (/(chrome|firefox|safari)/.test(t.toLowerCase())) {
      let e = ["padding: 12px 5px 10px", "background-color: #171718", "color: #bc994e", "font-size: 9px", "font-weight: semibold"].join(";");
      let i = ["padding: 12px 5px 10px", "background-color: #bc994e", "color: #ffffff", "font-size: 9px", "font-weight: semibold"].join(";");
      let n = ["background-color: transparent"].join(";");
      console.log("\n\n %c From the fields where passion plays, through the storms, we find our ways. In every loss, there’s something gained, a lesson learned, a new chapter framed.︎ %c Crafted with ♥ %c \n\n\n", e, i, n)
    } else {
      window.console && console.log("Crafted with love by PascalGlass - pascalglass@tgkonz.de");
    }
  }
}
export default createApp(App, {
  root: document.body,
  features: {
    asyncChildren: true
  }
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}
