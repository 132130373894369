import { Base, BaseProps } from '@studiometa/js-toolkit';
import type HeroDefault from './HeroDefault';
import type HeroHome from './HeroHome';

/**
 * Defines the properties interface for Hero component.
 */
interface HeroProps extends BaseProps {
  $children: {
    HeroDefault: Promise<HeroDefault>[];
    HeroHome: Promise<HeroHome>[];
  };
}

/**
 * Class representing a hero component.
 * @extends {Base<HeroProps>}
 */
export class Hero extends Base<HeroProps> {
  /**
   * Component config.
   */
  static config = {
    name: 'Hero',
    components: {
      HeroDefault: () => import('./HeroDefault.js'),
      HeroHome: () => import('./HeroHome.js'),
      
    }
  };

  /**
   * Helper method to apply clipPath coordinates.
   * @param {HTMLElement} element
   * @param {number[][]} coords
   */
  public applyClip(element: HTMLElement, coords: number[][]): void {
    const el = element;
    const a = [];

    coords.forEach((c) => {
      const [x, y] = c;
      a.push(`${x}% ${y}%`);
    });

    const str = `polygon(${a.join(',')})`;
    el.style.clipPath = str;
    el.style['webkitClipPath'] = str;
  }

  /**
   * Helper method to clean up clipPath property.
   * @param {HTMLElement} element
   */
  public cleanClip(element) {
    const el = element;
    el.style.clipPath = '';
    el.style.webkitClipPath = '';
  }

  /**
   * Helper method to calculate the coordinates for animating clipPath.
   * @returns {{obj: {top: number, bottom: number}, coords: number[][]}}
   */
  public initClip() {
    const obj = {
      top: 0,
      bottom: 0,
    };

    const coords = [
      [0, 0],
      [obj.top, 0],
      [obj.bottom, 100],
      [0, 100],
    ];

    return {
      obj,
      coords,
    };
  }
}

