import { Base } from '@studiometa/js-toolkit';
import type { BaseProps, ScrollServiceProps } from '@studiometa/js-toolkit';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

/**
 * Interface for ScrollToTop component props
 * @interface ScrollToTopProps
 * @extends BaseProps
 */
export interface ScrollToTopProps extends BaseProps {
  $options: {
    /** The scroll offset at which the button should appear */
    showOffset: number;
    /** The duration of the button's show/hide animation */
    animationDuration: number;
    /** The y-offset for the button's hidden position */
    yOffset: number;
  };
}

/**
 * ScrollToTop component class
 * @class ScrollToTop
 * @extends Base<ScrollToTopProps>
 */
export class ScrollToTop extends Base<ScrollToTopProps> {
  static config = {
    name: 'ScrollToTop',
    options: {
      showOffset: {
        type: Number,
        default: 300,
      },
      animationDuration: {
        type: Number,
        default: 0.3,
      },
      yOffset: {
        type: Number,
        default: 20,
      },
    },
  };

  private isVisible = false;

  mounted(): void {
    this.$el.addEventListener('click', this.scrollToTop.bind(this));
  }

  scrolled({ y }: ScrollServiceProps): void {
    if (y > this.$options.showOffset && !this.isVisible) {
      this.showTrigger();
    } else if (y <= this.$options.showOffset && this.isVisible) {
      this.hideTrigger();
    }
  }

  private showTrigger(): void {
    if (this.isVisible) return;
    this.isVisible = true;
    gsap.to(this.$el, {
      duration: this.$options.animationDuration,
      y: 0,
      opacity: 1,
      pointerEvents: 'auto',
      ease: 'power2.out',
      onStart: () => {
        this.$el.setAttribute('aria-hidden', 'false');
      }
    });
  }

  private hideTrigger(): void {
    if (!this.isVisible) return;
    this.isVisible = false;
    gsap.to(this.$el, {
      duration: this.$options.animationDuration,
      y: this.$options.yOffset,
      opacity: 0,
      pointerEvents: 'none',
      ease: 'power2.in',
      onComplete: () => {
        this.$el.setAttribute('aria-hidden', 'true');
      }
    });
  }

  private scrollToTop(): void {
    gsap.to(window, {
      duration: 0.5,
      scrollTo: {
        y: 0,
        autoKill: false
      },
      ease: 'power2.inOut',
    });
  }

  destroyed(): void {
    this.$el.removeEventListener('click', this.scrollToTop.bind(this));
  }
}
